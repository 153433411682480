import styled from 'styled-components'
import { primary } from 'utils/colors'

export const TextButtonWrapper = styled.span`
    border: none;
    width: fit-content;
    background-color: transparent;
    color: ${primary.blue};
    :hover {
        color: ${primary.lightBlue};
        text-decoration: underline;
    }
    :active {
        color: ${primary.darkBlue};
    }
`
