import PhoneInput from 'react-phone-input-2'

import styled from 'styled-components'

export const PhoneInputWrapper = styled(PhoneInput)`
    > div .selected-flag {
        margin-right: 2px;
    }
    > .form-control {
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 0.375rem;
        height: 38px !important;
    }
    .flag-dropdown {
        border-radius: 5px;
        height: 38px !important;
    }
`
