import React, { useEffect, useState } from 'react'

import { SelectDropdown } from 'apps/vendor/components/Selects'
import { ArticleType } from 'apps/vendor/pages/Articles'

import { ArticleSetType } from '../Modals/LicenseModal'
import { PartnerType } from '../PartnerList'
import { ArticleCurrencyDropdownContainer } from './ArticlesDropdownContainer.styled'

type ArticlesDropdownProps = {
    selectedArticles: ArticleSetType[]
    articleList: ArticleType[] | null
    selectedPartner: PartnerType | null
    usePartnerArticles: boolean
    handleAddArticle: (article: ArticleType) => void
}

export default function ArticlesDropdownContainer(
    defaultProps: ArticlesDropdownProps,
) {
    const {
        selectedArticles,
        articleList,
        selectedPartner,
        usePartnerArticles,
        handleAddArticle,
    } = defaultProps

    const [selectedCurrency, setSelectedCurrency] = useState<string | null>(
        null,
    )

    const dropdownArticlesCurrencies = articleList
        ? articleList.map((article: { currency: string }) => article.currency)
        : []

    const uniqueCurrencies: string[] = Array.from(
        new Set(dropdownArticlesCurrencies),
    )

    const filteredDropdownArticles =
        articleList?.filter(
            (article: { currency: string }) =>
                article.currency === selectedCurrency,
        ) || []

    const articlesAvailable = filteredDropdownArticles.length > 0
    const [className, setClassName] = useState('')

    useEffect(() => {
        if (
            (!usePartnerArticles || (usePartnerArticles && selectedPartner)) &&
            !articlesAvailable &&
            !selectedArticles.length
        ) {
            setClassName('missing-asset')
        } else {
            setClassName('')
        }
    }, [
        usePartnerArticles,
        selectedPartner,
        articlesAvailable,
        selectedArticles,
    ])

    useEffect(() => {
        if (selectedArticles.length > 0) {
            setSelectedCurrency(selectedArticles[0].article.currency)

            return
        }
        if (!selectedCurrency && uniqueCurrencies.length === 0) {
            setSelectedCurrency('SEK')

            return
        }
        if (
            uniqueCurrencies.length === 1 &&
            selectedCurrency !== uniqueCurrencies[0]
        ) {
            setSelectedCurrency(uniqueCurrencies[0])
        }
    }, [
        selectedArticles,
        selectedCurrency,
        uniqueCurrencies,
        usePartnerArticles,
    ])

    const renderCurrencyDropdown = () => {
        const options = uniqueCurrencies.map((currency) => ({
            value: currency,
            label: currency,
        }))

        const value =
            options.find((option) => option.value === selectedCurrency) || null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={setSelectedCurrency}
                isDisabled={
                    uniqueCurrencies.length <= 1 || selectedArticles.length > 0
                }
                placeholder={selectedCurrency || 'Currency'}
            />
        )
    }

    const renderArticlesDropdown = () => {
        const options = filteredDropdownArticles.map((article) => ({
            value: article.id,
            label: article.name,
        }))

        let placeholder = articlesAvailable
            ? 'Add article'
            : 'No articles available'

        if (usePartnerArticles && !selectedPartner) {
            placeholder = 'Select a partner'
        }

        return (
            <SelectDropdown
                options={options}
                onChange={(value) => {
                    const selectedArticle = articleList?.find(
                        (article) => article.id === value,
                    )

                    if (selectedArticle) {
                        handleAddArticle(selectedArticle)
                    }
                }}
                isDisabled={
                    className !== '' ||
                    (usePartnerArticles && !selectedPartner) ||
                    !articlesAvailable
                }
                value={null}
                placeholder={placeholder}
                isSearchable
            />
        )
    }

    return (
        <ArticleCurrencyDropdownContainer>
            {renderCurrencyDropdown()}
            {renderArticlesDropdown()}
        </ArticleCurrencyDropdownContainer>
    )
}
