import styled from 'styled-components'

export const InvoicingMethodContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin: 24px 8px;
    gap: 16px;

    * {
        cursor: pointer;
    }
`
