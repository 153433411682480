import React, { useState } from 'react'
import { Alert, Form, FormLabel } from 'react-bootstrap'

import { DoubleSection } from 'apps/vendor/components/Modals/Modals.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { LicenseStatus, TimePeriod } from 'apps/vendor/interfaces/subscriptions'

import { InvoicingMethodContainer } from './LicenseBillingSection.styled'

interface LicenseBillingSectionProps {
    preselectedValues: {
        licenseStatus: LicenseStatus
        paymentFrequency: TimePeriod
        startDate: string
        manualInvoicing: boolean
        invoiceStartDate?: string
    }
    partnerOwnedLicense: boolean
    onChangeStatus: (status: LicenseStatus) => void
    onChangePaymentFrequency: (frequency: number) => void
    onChangeStartDate: (startDate: string) => void
    onChangeInvoiceStartDate: (invoiceStartDate: string) => void
    onChangeManualInvoicing: (invoicedBySharlic: boolean) => void
}

export default function LicenseBillingSection(
    defaultProps: LicenseBillingSectionProps,
) {
    const {
        preselectedValues,
        partnerOwnedLicense,
        onChangePaymentFrequency,
        onChangeStartDate,
        onChangeInvoiceStartDate,
        onChangeManualInvoicing,
    } = defaultProps

    const [showBillingInfo, setShowBillingInfo] = useState(!partnerOwnedLicense)

    const [manualInvoicing, setManualInvoicing] = useState<boolean>(
        preselectedValues.manualInvoicing,
    )

    const selectedPaymentFrequency =
        preselectedValues.paymentFrequency || TimePeriod.MONTHLY

    const selectedStartDate = preselectedValues.startDate

    const selectedInvoiceStartDate = preselectedValues
        ? preselectedValues.invoiceStartDate
        : selectedStartDate

    const handleSetStartDate = (date: string) => {
        if (selectedInvoiceStartDate && date > selectedInvoiceStartDate) {
            onChangeInvoiceStartDate(date)
        }
        onChangeStartDate(date)
    }

    const handleSetInvoiceStartDate = (date: string) => {
        if (!manualInvoicing && date < selectedStartDate) {
            onChangeInvoiceStartDate(selectedStartDate)
        } else {
            onChangeInvoiceStartDate(date)
        }
    }

    const handleUpdateInvoicingMethod = (manualInvoiceSelected: boolean) => {
        if (!manualInvoicing) {
            onChangeInvoiceStartDate(selectedStartDate)
        }

        setManualInvoicing(manualInvoiceSelected)
        onChangeManualInvoicing(manualInvoiceSelected)
    }

    const renderStartDate = () => (
        <>
            <FormLabel>Start date</FormLabel>
            <Form.Control
                type="date"
                value={selectedStartDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleSetStartDate(e.target.value)
                }
                data-testid="start-date-input"
                disabled={partnerOwnedLicense}
            />
        </>
    )

    const renderInvoiceMethodRadioButtons = () => {
        return (
            <InvoicingMethodContainer>
                <Form.Check
                    type="radio"
                    id="billed-by-sharlic-radio"
                    label="Billed by Sharlic"
                    checked={!manualInvoicing}
                    onChange={() => handleUpdateInvoicingMethod(false)}
                    disabled={partnerOwnedLicense}
                />
                <Form.Check
                    type="radio"
                    id="manual-billing-radio"
                    label="Manual Invoicing"
                    checked={manualInvoicing}
                    onChange={() => handleUpdateInvoicingMethod(true)}
                    disabled={partnerOwnedLicense}
                />
            </InvoicingMethodContainer>
        )
    }

    const renderInvoiceStartDate = () => {
        return (
            <>
                <FormLabel>Billed by Sharlic from:</FormLabel>
                {manualInvoicing ? (
                    <Form.Control type="text" value="-" disabled />
                ) : (
                    <Form.Control
                        type="date"
                        value={selectedInvoiceStartDate}
                        min={selectedStartDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSetInvoiceStartDate(e.target.value)
                        }
                        disabled={partnerOwnedLicense}
                    />
                )}
            </>
        )
    }

    const renderStartDatesSection = () => {
        return (
            <DoubleSection>
                <div>{renderStartDate()}</div>
                <div>{renderInvoiceStartDate()}</div>
            </DoubleSection>
        )
    }

    const renderPaymentFrequencyDropdown = () => {
        const options = Object.values(TimePeriod).map((period, index) => ({
            value: index,
            label: period,
        }))

        const selectedOption = options.find(
            (option) => option.label === selectedPaymentFrequency,
        )

        const value = selectedOption
            ? {
                  value: selectedOption.value,
                  label: selectedOption.label.toString(),
              }
            : null

        return (
            <>
                <FormLabel>Payment frequency</FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={onChangePaymentFrequency}
                    isDisabled={partnerOwnedLicense}
                />
            </>
        )
    }

    const renderAlert = () => {
        if (!manualInvoicing) return null

        return (
            <Alert
                variant="primary"
                dismissible
                onClose={() => setShowBillingInfo(false)}
                show={showBillingInfo}
            >
                <p>
                    Manual billing means that Sharlic will not invoice the
                    customer. You will have to do this manually. <br />
                    It also means that Sharlic cannot handle revenue sharing or
                    automated kickbacks to your partners.
                </p>
            </Alert>
        )
    }

    return (
        <>
            {renderInvoiceMethodRadioButtons()}
            {renderStartDatesSection()}
            {renderPaymentFrequencyDropdown()}
            {renderAlert()}
        </>
    )
}
