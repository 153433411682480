import React from 'react'

import BootstrapTable from 'apps/shared/components/BootstrapTable/BootstrapTable'
import { BooleanIcon, InvoiceStatusBadge } from 'apps/shared/components/Icons'
import {
    CustomerSubscriptionDataType,
    LicenseType,
    LicenseStatus,
} from 'apps/vendor/interfaces/subscriptions'
import { formatDate, localeNumber } from 'apps/vendor/utils'
import useUser from 'hooks/useUser'

import { TableWrapper } from '../Tables.styled'

export default function CustomerSubscriptionsTable(props: {
    customerData: CustomerSubscriptionDataType
    setSelectedLicense: (license: LicenseType, customer: string) => void
}): JSX.Element {
    const { customerData, setSelectedLicense } = props
    const { user } = useUser()

    const maxNumberOfPartners = customerData.licenses.reduce((max, license) => {
        return Math.max(max, license.sales_tiers.length) || 1
    }, 0)

    const getPartnerHeaders = () => {
        const headers = ['Producer', 'Distributor', 'Reseller']

        switch (maxNumberOfPartners) {
            case 1:
                return [headers[0]]
            case 2:
                return [headers[0], headers[2]]
            default:
                return headers
        }
    }

    const getHeaders = () => {
        const partnerHeaders = getPartnerHeaders()

        const headers = [
            'License',
            'Total price',
            'Currency / Term',
            'Payment Frequency',
            'Status',
            'Next invoice',
            ...partnerHeaders,
            'Active',
        ]

        return headers
    }

    const generatePartnerAccessors = () => {
        const partnerAccessors = []

        for (let i = 0; i < maxNumberOfPartners; i += 1) {
            partnerAccessors.push(`partnerNames[${i}]`)
        }

        return partnerAccessors
    }

    const generateLicenseStatusBadge = (status: string) => {
        let statusText = status.toUpperCase()

        if (statusText === 'TRANSFER CREATED') {
            statusText = 'PAID'
        }

        return <InvoiceStatusBadge status={statusText} />
    }

    const accessors = [
        'name',
        'total_price',
        'currencyTerm',
        'payment_frequency',
        'status',
        'next_invoice_due_date',
        ...generatePartnerAccessors(),
        'active',
    ]

    const getData = () => {
        return customerData.licenses.map((subscription) => {
            const currencyTerm = `${subscription.articles[0]?.article.currency}
                / ${subscription.term}`
            const partnerNames = []

            const status = generateLicenseStatusBadge(
                subscription.invoice_status || '',
            )

            if (subscription.sales_tiers.length === 0) {
                partnerNames.push(
                    <span key="vendor-0">{subscription.vendor}</span>,
                )
                partnerNames.push(<span key="empty-1">-</span>)
                partnerNames.push(<span key="empty-2">-</span>)
            } else {
                for (let i = 0; i < subscription.sales_tiers.length; i += 1) {
                    const { vendor } = subscription.sales_tiers[i]

                    const name =
                        'partner_name' in vendor
                            ? vendor.partner_name
                            : vendor.name

                    if (
                        subscription.sales_tiers.length === 1 &&
                        maxNumberOfPartners > 1
                    ) {
                        partnerNames.push(
                            <span key={`vendor-${i}`}>{name}</span>,
                        )
                        partnerNames.push(<span key={`empty-${i}`}>-</span>)
                        partnerNames.push(<span key={`empty-${i + 1}`}>-</span>)
                    } else if (
                        i === 1 &&
                        maxNumberOfPartners === 3 &&
                        subscription.sales_tiers.length === 2
                    ) {
                        partnerNames.push(<span key={`empty-${i}`}>-</span>)

                        partnerNames.push(
                            <span key={`vendor-${i}`}>{name}</span>,
                        )
                    } else {
                        partnerNames.push(
                            <span key={`vendor-${i}`}>{name}</span>,
                        )
                    }
                }
            }

            return {
                ...subscription,
                currencyTerm,
                partnerNames,
                total_price: localeNumber(subscription.total_price, {
                    locale: user?.locale,
                }),
                status,
                next_invoice_due_date: formatDate(
                    new Date(subscription.next_invoice_due_date || '-'),
                    user?.locale,
                ),
                active: (
                    <BooleanIcon
                        checked={subscription.status !== LicenseStatus.INACTIVE}
                    />
                ),
                license: subscription,
            }
        })
    }

    return (
        <TableWrapper numberOfColumns={7 + maxNumberOfPartners}>
            <BootstrapTable
                striped
                headers={getHeaders()}
                data={getData()}
                accessors={accessors}
                onRowClicked={(item) =>
                    setSelectedLicense(item.license, customerData.name)
                }
                centeredHeaders={['Active', 'Status']}
                rightAlignedHeaders={['Total price']}
            />
        </TableWrapper>
    )
}
