import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { vendorApiParams } from 'api/utils/interfaces'
import { StripeLogin } from 'api/vendors'
import patchCurrentVendor from 'api/vendors/patchCurrentVendor'
import { StripeBadge } from 'apps/shared/components/Badges'
import {
    EmailInput,
    PhoneInputField,
} from 'apps/shared/components/FormInputFields'
import { UploadLogo } from 'apps/shared/components/UploadLogo'
import {
    ErrorContainer,
    ErrorMessage,
    FormLabel,
} from 'apps/shared/shared.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { CountryCode } from 'apps/vendor/interfaces/subscriptions'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'
import useVendor from 'hooks/useVendor'
import { loadVendor } from 'state/vendor'
import isEmail from 'validator/lib/isEmail'

import { PageLine } from '../pages.styled'
import {
    Container,
    EditButton,
    LogoContainer,
    SettingsFormGroup,
    StripeEditableField,
} from './Settings.styled'

export default function Settings() {
    const { vendor } = useVendor()
    const { user } = useUser()
    const dispatch = useDispatch()
    const { errorToast, promiseToast, successToast } = useToast()
    const [canSubmit, setCanSubmit] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const userIsAdmin = user?.permissions?.includes('is_vendor_admin') ?? false

    const [emailWarning, setEmailWarning] = useState({
        contactEmail: '',
        invoiceEmail: '',
    })

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [phoneNumber, setPhoneNumber] = useState(vendor?.phone_number || '')

    const [organizationNumber, setOrganizationNumber] = useState(
        vendor?.organization_number || '',
    )

    const [taxNumber, setTaxNumber] = useState(vendor?.tax_number || '')

    const [contactEmail, setContactEmail] = useState(
        vendor?.contact_email || '',
    )

    const [invoiceEmail, setInvoiceEmail] = useState(
        vendor?.invoice_email || '',
    )
    const [formIsDirty, setFormIsDirty] = useState(false)

    useEffect(() => {
        if (vendor) {
            setName(vendor.name || '')
            setAddress(vendor.address_line_1 || '')
            setAddress2(vendor.address_line_2 || '')
            setCity(vendor.city || '')
            setZipCode(vendor.zip_code || '')
            setCountry(vendor.country || '')
            setState(vendor.state || '')

            setPhoneNumber(vendor.phone_number || '')
            setOrganizationNumber(vendor.organization_number || '')
            setTaxNumber(vendor.tax_number || '')
            setContactEmail(vendor.contact_email || '')
            setInvoiceEmail(vendor.invoice_email || '')
        }
    }, [vendor])

    useEffect(() => {
        if (vendor) {
            setFormIsDirty(
                vendor.name !== name ||
                    vendor.address_line_1 !== address ||
                    vendor.address_line_2 !== address2 ||
                    vendor.city !== city ||
                    vendor.zip_code !== zipCode ||
                    vendor.country !== country ||
                    vendor.state !== state ||
                    vendor.phone_number !== phoneNumber ||
                    vendor.organization_number !== organizationNumber ||
                    vendor.tax_number !== taxNumber ||
                    vendor.contact_email !== contactEmail ||
                    vendor.invoice_email !== invoiceEmail,
            )
        }
    }, [
        vendor,
        name,
        address,
        address2,
        city,
        zipCode,
        country,
        state,
        phoneNumber,
        organizationNumber,
        taxNumber,
        contactEmail,
        invoiceEmail,
    ])

    useEffect(() => {
        const isValidContactEmail = isEmail(contactEmail)
        const isValidInvoiceEmail = isEmail(invoiceEmail)

        const formIsValid =
            isValidContactEmail && isValidInvoiceEmail && !phoneNumberError

        setCanSubmit(formIsValid && formIsDirty)
    }, [contactEmail, invoiceEmail, phoneNumberError, formIsDirty])

    const handleSendToStripe = () => {
        const toastData = {
            promise: StripeLogin(),
            state: {
                pending: {
                    render() {
                        return 'You will soon be redirected to Stripe'
                    },
                    icon: false,
                },
                success: {
                    render({ data }: any) {
                        window.setTimeout(() => {
                            window.open(data?.data?.url, '_blank')
                        }, 2000)

                        return 'Redirecting to stripe'
                    },
                    icon: false,
                },
                error: {
                    render() {
                        return 'Something went wrong, please try again'
                    },
                    icon: false,
                },
            },
        }
        promiseToast({ promise: toastData, autoclose: 2000 })
    }

    const isValidVendorInfo = (vendorInfo: vendorApiParams) => {
        const requiredFields: (keyof vendorApiParams)[] = [
            'name',
            'address_line_1',
            'city',
            'zip_code',
            'country',
            'organization_number',
            'tax_number',
            'contact_email',
            'invoice_email',
        ]

        return requiredFields.every((field) => vendorInfo[field] !== null)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()

        const vendorData = {
            name,
            address_line_1: address,
            address_line_2: address2,
            city,
            zip_code: zipCode,
            country: country as CountryCode,
            state,
            phone_number: phoneNumber,
            organization_number: organizationNumber,
            tax_number: taxNumber,
            contact_email: contactEmail,
            invoice_email: invoiceEmail,
        }

        const submittableInfo = Object.entries(vendorData).reduce(
            (newVendorInfo, [key, value]) => {
                if (value !== null) {
                    return {
                        ...newVendorInfo,
                        [key as keyof vendorApiParams]: value,
                    }
                }

                return newVendorInfo
            },
            {} as vendorApiParams,
        )

        if (!isValidVendorInfo(submittableInfo)) {
            errorToast('Please fill out all required fields')

            return
        }

        patchCurrentVendor(submittableInfo)
            .then(() => {
                dispatch(loadVendor())
                setFormIsDirty(false)
                successToast('Changes saved')
            })
            .catch((err) => {
                if (err.response.status !== 500) {
                    return errorToast(
                        'Failed to save changes, please try again.',
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const handleSetEmail = (
        value: string,
        emailType: 'contactEmail' | 'invoiceEmail',
    ) => {
        const setEmail =
            emailType === 'contactEmail' ? setContactEmail : setInvoiceEmail

        setEmail(value)
        setEmailWarning({ ...emailWarning, [emailType]: '' })
    }

    const validateEmail = (emailType: 'contactEmail' | 'invoiceEmail') => {
        const email = emailType === 'contactEmail' ? contactEmail : invoiceEmail
        if (!email) return

        const isValid = isEmail(email)
        const warning = isValid ? '' : 'Invalid email format'

        setEmailWarning({ ...emailWarning, [emailType]: warning })
    }

    const renderEmailField = (
        emailType: 'contactEmail' | 'invoiceEmail',
        placeholder?: string,
    ) => {
        const emailValue =
            emailType === 'contactEmail' ? contactEmail : invoiceEmail

        return (
            <>
                <EmailInput
                    value={emailValue || ''}
                    placeholder={placeholder}
                    onChange={(value: string) =>
                        handleSetEmail(value, emailType)
                    }
                    onBlur={() => {
                        validateEmail(emailType)
                    }}
                    displayIcon
                    disabled={!userIsAdmin}
                />
                <ErrorContainer>
                    {emailWarning[emailType] && (
                        <ErrorMessage>{emailWarning[emailType]}</ErrorMessage>
                    )}
                </ErrorContainer>
            </>
        )
    }

    const handleSetCountry = (countryCode: string) => {
        setCountry(countryCode)
        setState('')
        setPhoneNumberError('')

        setPhoneNumber('')
    }

    const renderStripeSettingsSection = () => {
        return (
            <InputGroup className="mb-4">
                <InputGroup.Text>
                    <StripeBadge />
                </InputGroup.Text>
                <StripeEditableField disabled required value={name} />
                <EditButton onClick={handleSendToStripe}>
                    <div className="fa fa-pen" />
                </EditButton>
            </InputGroup>
        )
    }

    const renderPhoneSection = () => {
        return (
            <>
                <FormLabel>
                    Phone number
                    <span> (optional)</span>
                </FormLabel>
                <PhoneInputField
                    {...{
                        phoneNumber,
                        onChange: (phoneInput, errorMessage) => {
                            setPhoneNumber(phoneInput)
                            setPhoneNumberError(errorMessage)
                        },
                        selectedCountry: country as CountryCode,
                        disabled: !userIsAdmin,
                    }}
                />
                <ErrorContainer>
                    {phoneNumberError && (
                        <ErrorMessage>{phoneNumberError}</ErrorMessage>
                    )}
                </ErrorContainer>
            </>
        )
    }

    const renderCountryDropdown = () => {
        const countries = [
            { code: 'SE', name: 'Sweden' },
            { code: 'US', name: 'United States' },
        ]

        const options = countries.map((countryItem) => ({
            value: countryItem.code,
            label: countryItem.name,
        }))

        const value = options.find((option) => option.value === country) || null

        return (
            <div style={{ marginBottom: '0.5rem' }}>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={handleSetCountry}
                    placeholder="Country"
                    isDisabled={!userIsAdmin}
                />
            </div>
        )
    }

    const generateTextInputField = (
        value: string,
        onChange: (value: string) => void,
        placeholder?: string,
    ) => {
        if (value === null) {
            return null
        }

        return (
            <Form.Control
                {...{
                    id: placeholder,
                    placeholder,
                    value,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(e.target.value),
                    disabled: !userIsAdmin,
                }}
            />
        )
    }

    const renderBillingInfo = () => {
        if (!country) {
            return null
        }

        return (
            <>
                {generateTextInputField(address, setAddress, 'Address field 1')}
                {generateTextInputField(
                    address2,
                    setAddress2,
                    'Address field 2 (optional)',
                )}
                {generateTextInputField(zipCode, setZipCode, 'Zip code')}
                {generateTextInputField(city, setCity, 'City')}
                {country === 'US' &&
                    generateTextInputField(state, setState, 'State')}
            </>
        )
    }

    const renderBillingInfoSection = () => {
        return (
            <>
                <FormLabel>Billing details</FormLabel>
                {renderCountryDropdown()}
                {renderBillingInfo()}
                {generateTextInputField(
                    organizationNumber,
                    setOrganizationNumber,
                    'Organization number',
                )}
                {generateTextInputField(taxNumber, setTaxNumber, 'Tax number')}
                {renderEmailField('invoiceEmail', 'Enter invoice email')}
            </>
        )
    }

    const renderContactInfoSection = () => {
        return (
            <>
                <FormLabel>Contact email</FormLabel>
                {renderEmailField('contactEmail')}
                {renderBillingInfoSection()}
                {renderPhoneSection()}
            </>
        )
    }

    const renderSaveFormButton = () => {
        return (
            <Button
                className="rounded"
                type="submit"
                id="submit-button"
                disabled={!canSubmit}
            >
                Save changes
            </Button>
        )
    }

    const renderVendorSettingsSection = () => {
        return (
            <Form id="settings-form" onSubmit={handleSubmit}>
                <SettingsFormGroup id="settings-form-group ">
                    {renderContactInfoSection()}
                    {renderSaveFormButton()}
                </SettingsFormGroup>
            </Form>
        )
    }

    const renderLogoSection = () => {
        return (
            <LogoContainer>
                <FormLabel>
                    Logo<span> (optional)</span>
                </FormLabel>
                <UploadLogo disabled={!userIsAdmin} />
            </LogoContainer>
        )
    }

    return (
        <Container>
            {renderStripeSettingsSection()}
            {renderVendorSettingsSection()}
            <PageLine />
            {renderLogoSection()}
        </Container>
    )
}
