import { Form } from 'react-bootstrap'

import styled from 'styled-components'
import { primary } from 'utils/colors'

export const DoubleSection = styled.section`
    > div > * {
        margin-bottom: 0.5rem;
    }

    @media screen and (min-width: 991px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-bottom: 0;
    }
`

export const ModalWrapper = styled.div`
    > * {
        margin-bottom: 0.5rem;
    }
`

export const Checkbox = styled(Form.Check)`
    > label {
        font-size: 0.9rem;
    }
    * {
        cursor: pointer;
    }
`

export const RemoveListItemButton = styled.i`
    color: ${primary.blue};
`

export const MissingAssetText = styled.p`
    color: ${primary.red};
    font-style: italic;
`
